<template>
  <div :class="`hexagon ${useImage}`" :style="hexaStyle" :edited="edited" :active="active" :selected="selected" :orientation="orientation">
    <Sheep v-if="useImage=='sheep'" :color="color" :width="Math.ceil(hex.width)" :height="Math.ceil(hex.height)" viewbox="0 -25 193.547 277.906"></Sheep>
    <Octopuss v-if="useImage=='octopuss'" :color="color" :width="Math.ceil(hex.width)" :height="Math.ceil(hex.height)" viewbox="150 150 1748 1748"></Octopuss>
    <svg v-if="!useImage" version="1.1" xmlns="http://www.w3.org/2000/svg" :width="Math.ceil(hex.width)" :height="Math.ceil(hex.height)" :viewbox="hex.getViewbox()">
        <path :fill="hex.getColor(color, selected)" :stroke="hex.getStroke(selected)" :stroke-width="hex.getStrokeWidth(selected)" :stroke-dasharray="hex.getDashArray()" :d="hex.getPath()" pointer-events="visiblePainted"></path>
      </svg>

    <div class="text" v-if="!active" />

    <div class="info" v-if="info">{{info}}</div>

    <contenteditable v-if="active"
                     tag="div"
                     class="text"
                     ref="contenteditableref"
                     :contenteditable="edited"
                     v-model="state.text"
                     :empty="!state.text || !state.text.trim()"
                     :interceptEnter="interceptEnter"
                     @returned="$emit('editdone')"
                     @keyup.escape="$emit('editdone')"
                     placeholder="enter description" />

    <v-swatches v-if="active"
                class="colorpicker"
                popover-x="left"
                popover-y="top"
                :trigger-style="triggerStyle"
                :wrapper-style="wrapperStyle"
                :value="state.color"
                :swatches="palette"
                :rowLength=paletteWidth
                @close="(color) => state.color=color"></v-swatches>

  </div>
</template>

<script>
  import { ref, reactive, computed, watch, onMounted, nextTick } from 'vue'
  import contenteditable from 'vue-contenteditable'
  import Hex from '@/hexagon.js'
  import VSwatches from 'vue-swatches'
  import 'vue-swatches/dist/vue-swatches.css'
  import Sheep from '@/components/Sheep.vue'
  import Octopuss from '@/components/Octopuss.vue'

  export default {
    name: 'Hexagon',
    emits: ['change', 'editdone'],
    components: {
      Sheep,
      Octopuss,
      contenteditable,
      VSwatches
    },
    props: {
      size: Number,
      borderRadius: Number,
      orientation: String,
      interceptEnter: {
        type: Boolean,
        default: true
      },
      active: {
        type: Boolean,
        default: false
      },
      selected: {
        type: Boolean,
        default: false
      },
      useImage: {
        type: String,
        default: null
      },
      edited: {
        type: Boolean,
        default: false
      },
      locked: {
        type: Boolean,
        default: false
      },
      text: {
        type: String,
        default: ''
      },
      info: {
        type: String,
        default: null
      },
      color: {
        type: String,
        default: 'gray'
      },
      palette: Array,
      paletteWidth: Number,
      paletteHorizontalSeparator: {
        type: Boolean,
        default: false
      }
    },
    setup(props, { emit }) {
      const contenteditableref = ref(null)

      // contenteditable is not working well will empty strings
      const prepareText = text => {
        return text || '\n'
      }

      const state = reactive({
        text: prepareText(props.text),
        color: props.color,
        updatingFropProp: false
      })

      // emit change when text or color is changed
      watch([() => state.text, () => state.color], () => {
        // disable watcher while updating state from props
        // otherwise a change event would be emitted when a model is loaded or applied with Undo / Redo
        // which would in turn incorrectly add the model to the Undo stack
        if (!state.updatingFropProp) emit('change', state)
      })

      // reset state text when text prop is changed
      watch(
        () => props.text,
        async newValue => {
          state.updatingFropProp = true
          state.text = prepareText(newValue)
          await nextTick()
          state.updatingFropProp = false
        }
      )

      // reset state color when color prop is changed
      watch(
        () => props.color,
        async newValue => {
          state.updatingFropProp = true
          state.color = newValue
          await nextTick()
          state.updatingFropProp = false
        }
      )

      const focus = () => {
        if (contenteditableref.value)
        setTimeout(() => {
          contenteditableref.value.element.focus()
        }, 0)
      }

      // focus when mounted edited
      onMounted(() => {
        if (props.edited) focus()
      })

      // focus after switched to edited
      watch(
        () => props.edited,
        newValue => {
          if (newValue) focus()
        },
        {
          flush: 'post'
        }
      )

      // computed CSS style property
      const hexaStyle = computed(() => {
        return {
          '--size': props.size + 'px'
        }
      })

      const triggerStyle = computed(() => {
        let factor = props.orientation == Hex.POINTY_TOP ? 7 : 7
        return {
          display: props.locked ? 'none' : '',
          width: `calc(var(--size) / ${factor})`,
          height: `calc(var(--size) / ${factor})`,
          boxSizing: 'border-box',
          border: '2px solid #bbb',
          borderRadius: '50%',
          filter: 'brightness(120%)'
        }
      })

      const wrapperStyle = computed(() => {
        return props.paletteHorizontalSeparator ? {
          background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M3 49 L97 49' stroke='darkgray' stroke-width='0.1' /></svg>")`,
          backgroundRepeat:'no-repeat',
          backgroundPosition:'center center',
          backgroundSize: '100% 100%, auto',
        } : null
      })

      // computed SVG element
      const hex = computed(() => {
        return new Hex(props.size, props.orientation, props.borderRadius)
      })

      return {
        state,
        contenteditableref,
        hexaStyle,
        triggerStyle,
        wrapperStyle,
        hex
      }
    }
  }
</script>

<style lang="scss">
  @import '@/styles/Hexagon';
</style>
