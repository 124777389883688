Number.prototype.mod = function (d) {
  return ((this % d) + d) % d
}

Number.prototype.even = function () {
  return this % 2 == 0
}

Number.prototype.odd = function () {
  return this % 2 != 0
}

Number.prototype.next = function (even) {
  return even ? this + this.mod(2) : this + 1 - this.mod(2)
}

Number.prototype.prev = function (even) {
  return even ? this - this.mod(2) : this - 1 + this.mod(2)
}

Number.prototype.closest = function (even) {
  // return either ceil or floor, the one which is even or odd as requested
  const ceil = Math.ceil(this)
  return ceil.mod(2) == (even ? 0 : 1) ? ceil : ceil - 1
}

String.prototype.contains = function (str) {
  return (this.match(str) == str)
}

String.prototype.startsWith = function (str) {
  return (this.match('^' + str) == str)
}

String.prototype.endsWith = function (str) {
  return (this.match(str + '$') == str)
}

const now = () => {
  if (typeof window !== 'undefined') return window.performance.now()
  return process.hrtime()
}

const spent = start => {
  let ms = 0
  if (typeof window !== 'undefined') ms = window.performance.now() - start
  else {
    const diff = process.hrtime(start)
    ms = (diff[0] * 1e9 + diff[1]) / 1e6
  }
  return ms.toFixed(2) + ' ms'
}

export default {
  now,
  spent
}
