import Hex from './hexagon.js'
import Utils from './utils.js'

function Node (id, text, color, x, y) {
  this.id = id
  this.text = text || ''
  this.color = color || null
  this.x = x
  this.y = y
  this.active = !!this.color
  this.selected = false
  this.focused = false
}

function HexModel (angle, options, restoring) {
  this.nodes = new Map()
  this.angle = angle || 0

  // not persisted (rebuilt by restore)
  this.nextId = 1
  this.nodesByCoordinate = new Map()

  // not persisted options (given back at restore)
  if (!options) options = {}
  this.radius = typeof options.radius === 'undefined' ? 1 : options.radius
  this.useGrid = typeof options.useGrid === 'undefined' ? false : !!options.useGrid
  this.gridWidth = typeof options.gridWidth === 'undefined' ? 4 : options.gridWidth
  this.gridHeight = typeof options.gridHeight === 'undefined' ? 3 : options.gridHeight
  this.autoGrow = typeof options.autoGrow === 'undefined' ? true : !!options.autoGrow
  this.debug = typeof options.debug === 'undefined' ? true : !!options.debug

  // for indenting logs
  this.indent = 0

  // add initial empty nodes (unless restoring)
  if (!restoring) this.addEmptyNodes()
}

HexModel.prototype.log = function (message) {
  if (this.debug) {
    for (let c = 0; c < this.indent; c++) message = '\t' + message
    console.log(message)
  }
}

HexModel.prototype.toJSON = function () {
  // node coordinates are expressed relative to ou angle so we must save the angle with the nodes
  return {
    angle: this.angle,
    nodes: [...this.nodes.values()].filter((node) => { return node.active })
  }
}

HexModel.restore = function (obj, angle, options) {
  // compatibility with old version
  if (Array.isArray(obj)) obj = { nodes: obj, angle: 0 }

  let model = new HexModel(obj.angle, options, true)
  model.nodes = new Map()
  model.nodesByCoordinate = new Map()

  // restore nodes
  if (Array.isArray(obj.nodes)) for (let o of obj.nodes) model.add_(o.id, o.x, o.y, o.text, o.color)

  // set nextId to the largest id plus one
  for (const [, node] of model.nodes) model.nextId = Math.max(model.nextId, node.id + 1)

  // convert if current angle and given angle are different then add initial empty nodes
  if (!model.setAngle(angle)) model.addEmptyNodes()

  return model
}

HexModel.prototype.count = function () {
  return [...this.nodes.values()].filter((node) => { return node.active }).length
}

HexModel.prototype.add_ = function (id, x, y, text, color) {
  // create, add and index node by its coordinates
  let node = new Node(id, text, color, x, y)
  this.nodes.set(node.id, node)
  this.nodesByCoordinate.set(`${node.x},${node.y}`, node)
  return node
}

HexModel.prototype.add = function (x, y, text, color) {
  // check that spot is free / remove empty node if any
  if (this.hasAt(x, y)) {
    let node = this.getAt(x, y)
    if (node.active) throw Error(`Node ${node.id} already exists at (${x},${y})`)
    this.log(`Removing inactive node ${node.id} at (${node.x},${node.y})`)
    this.remove_(node)
  }

  let node = this.add_(this.nextId++, x, y, text, color)
  this.log(`Added ${node.active ? 'active' : 'empty'} node ${node.id} at (${x},${y})`)

  // add empty nodes after this change
  this.addEmptyNodes()
  return node
}

HexModel.prototype.addNextTo = function (anchor, anchorSide, text, color) {
  const [off_x, off_y] = this.getOffsetXY(anchorSide)
  return this.add(anchor.x + off_x, anchor.y + off_y, text, color)
}

HexModel.prototype.hasAt = function (x, y) {
  return this.nodesByCoordinate.has(`${x},${y}`)
}

HexModel.prototype.getAt = function (x, y) {
  return this.nodesByCoordinate.get(`${x},${y}`)
}

HexModel.prototype.setColor = function (nodeId, color) {
  let node = this.nodes.get(parseInt(nodeId, 10))
  if (node) {

    if (!node.selected) node.color = color
    else {

      // changed color for a selected node: apply to whole selection
      for (const [, node_] of this.nodes) if (node_.selected) node_.color = color
    }
  }
}

HexModel.prototype.remove_ = function (node) {
  if (this.getAt(node.x, node.y) !== node) throw Error(`Inconsistency detected: node ${this.getAt(node.x, node.y).id} referenced by index instead of ${node.id}`)
  this.nodesByCoordinate.delete(`${node.x},${node.y}`)
  this.nodes.delete(node.id)
}

HexModel.prototype.removeSelected = function () {
  for (const [, node_] of this.nodes) if (node_.selected) {
    this.log(`Removing node ${node_.id}`)
    this.remove_(node_)
  }

  // add empty nodes after this change
  this.addEmptyNodes()
}

HexModel.prototype.remove = function (nodeId) {
  let node = this.nodes.get(parseInt(nodeId, 10))
  if (node) {

    if (!node.selected) {
      this.log(`Removing node ${node.id}`)
      this.remove_(node)

      // add empty nodes after this change
      this.addEmptyNodes()
    }
    else {

      // removing a selected node: remove the whole selection
      this.removeSelected()
    }

  } else this.log(`Node ${nodeId} not found, cannot remvove`)
}

HexModel.prototype.move_ = function (node, x, y) {

  // swap with existing node if any
  if (this.hasAt(x, y)) {
    let other = this.getAt(x, y)
    this.log(`Swapping node ${other.id} at ${x},${y} with node ${node.id} at ${node.x},${node.y}`)
    other.x = node.x
    other.y = node.y
    this.nodesByCoordinate.set(`${other.x},${other.y}`, other)
  } else {
    this.log(`Moving node ${node.id} to ${x},${y}`)
    this.nodesByCoordinate.delete(`${node.x},${node.y}`)
  }

  node.x = x
  node.y = y
  this.nodesByCoordinate.set(`${node.x},${node.y}`, node)
}

HexModel.prototype.move = function (nodeId, x, y) {
  let node = this.nodes.get(parseInt(nodeId, 10))
  if (node) {

    if (!node.selected) this.move_(node, x, y)
    else {

      // moving a selected node: move the whole selection

      // get and store target position for each selected node (before doing any move)
      for (const [, node_] of this.nodes) if (node_.selected) {
        node_.x_target = x + node_.x - node.x
        node_.y_target = y + node_.y - node.y
      }

      // move all selected nodes to target
      for (const [, node_] of this.nodes) if (node_.selected) {
        this.move_(node_, node_.x_target, node_.y_target)
      }
    }
  }

  // add empty nodes after this change
  this.addEmptyNodes()
}

HexModel.prototype.copy = function (nodeId, x, y) {
  let node = this.nodes.get(parseInt(nodeId, 10))
  if (node) {

    // add a new node with same text and color
    if (!node.selected) {
      try {
        this.add(x, y, node.text, node.color)
      } catch (e) {
        throw Error("Cannot copy over an existing hexagon.")
      }
    }
    else {

      // copying a selected node: copy the whole selection

      // get, store and validate target position for each selected node (before doing any copy)
      for (const [, node_] of this.nodes) if (node_.selected) {
        node_.x_target = x + node_.x - node.x
        node_.y_target = y + node_.y - node.y

        if (this.hasAt(node_.x_target, node_.y_target)) {
          let existing = this.getAt(node_.x_target, node_.y_target)
          if (existing.active) throw Error("Cannot copy selection over existing hexagons.")
        }
      }

      // copy all selected nodes to target
      for (const [, node_] of this.nodes) if (node_.selected) {
        this.add(node_.x_target, node_.y_target, node_.text, node_.color)
      }
    }
  }

  // add empty nodes after this change
  this.addEmptyNodes()
}

HexModel.prototype.setSelection = function (nodeIds) {
  this.clearSelection()
  this.appendSelection(nodeIds)
}

HexModel.prototype.appendSelection = function (nodeIds) {
  for (let nodeId of nodeIds) this.setSelected(nodeId, true)
}

HexModel.prototype.setSelected = function (nodeId, selected) {
  let node = this.nodes.get(parseInt(nodeId, 10))
  if (node && node.active) {
    node.selected = selected
  }
}

HexModel.prototype.hasSelection = function () {
  for (const [, node] of this.nodes) if (node.selected) return true
  return false
}

HexModel.prototype.nbSelected = function () {
  let nb = 0
  for (const [, node] of this.nodes) if (node.selected) nb++
  return nb
}

HexModel.prototype.clearSelection = function () {
  for (const [, node] of this.nodes) node.selected = false
}

HexModel.prototype.hasFocus = function () {
  for (const [, node] of this.nodes) if (node.focused) return true
  return false
}

HexModel.prototype.nbFocused = function () {
  let nb = 0
  for (const [, node] of this.nodes) if (node.focused) nb++
  return nb
}

HexModel.prototype.clearFocus = function () {
  for (const [, node] of this.nodes) node.focused = false
}

HexModel.prototype.center = function () {
  // remove existing empty nodes
  for (const [, node] of this.nodes) if (!node.active) this.remove_(node)

  // get translation vector to bring nodes center to grid center
  const gridCenter = this.getCenter(this.getGrid(true))
  const nodesCenter = this.getCenter(this.nodes.values())
  const translation = { x: gridCenter.x - nodesCenter.x, y: gridCenter.y - nodesCenter.y }

  // translate and reindex all nodes
  this.nodesByCoordinate = new Map()
  for (const [, node] of this.nodes) {
    [node.x, node.y] = this.snap(node.x + translation.x, node.y + translation.y)
    this.nodesByCoordinate.set(`${node.x},${node.y}`, node)
  }

  this.addEmptyNodes()
}

HexModel.prototype.addEmptyNodes = function () {
  let start = Utils.now()

  // remove existing empty nodes
  for (const [, node] of this.nodes) if (!node.active) this.remove_(node)
  let before = this.nodes.size

  // we are going to reuse all free ids starting from 1
  this.nextId = 1

  // grid W x H
  if (this.useGrid) {
    this.log(`Creating a ${this.gridWidth} x ${this.gridHeight} grid of empty nodes${this.autoGrow ? ' (with auto-grow)' : ''}`)
    this.indent++
    for (let node of this.getGrid()) {
      if (!this.hasAt(node.x, node.y)) {
        while (this.nodes.has(this.nextId)) this.nextId++
        this.add_(this.nextId++, node.x, node.y)
      }
    }
  }
  // radius around active nodes
  else {
    this.log(`Adding empty nodes for a radius of ${this.radius}`)
    this.indent++
    for (let distance = 0; distance < this.radius; distance++) {
      // create empty nodes in free spots around existing nodes (iterate on a shallow copy of existing nodes at this moment)
      for (const node of [...this.nodes.values()]) {
        for (let side = 1; side <= 6; side++) {
          const [off_x, off_y] = this.getOffsetXY(side)
          if (!this.hasAt(node.x + off_x, node.y + off_y)) {
            while (this.nodes.has(this.nextId)) this.nextId++
            this.add_(this.nextId++, node.x + off_x, node.y + off_y)
          }
        }
      }
    }
  }

  // set nextId to the largest id plus one
  for (const [, node] of this.nodes) this.nextId = Math.max(this.nextId, node.id + 1)

  // add a first empty node if needed
  if (this.nodes.size == 0) this.add_(this.nextId++, 0, 0)

  this.indent--
  this.log(`Added ${this.nodes.size - before} empty nodes in ${Utils.spent(start)}`)
}

HexModel.prototype.xStep = function () {
  // step between consecutive nodes on a same row
  return this.orientation(this.angle) == Hex.POINTY_TOP ? 2 : 1
}

HexModel.prototype.yStep = function () {
  // step between consecutive nodes on a same column
  return this.orientation(this.angle) == Hex.POINTY_TOP ? 1 : 2
}

HexModel.prototype.getGrid = function (disableAutoGrow) {
  const [xStep, yStep] = [this.xStep(), this.yStep()]

  // get fixed grid bounds
  const bounds = { left: 0, right: this.gridWidth * xStep - xStep, top: 0, bottom: this.gridHeight * yStep - yStep}

  if (this.autoGrow && !disableAutoGrow) {
    // autogrow to include all (active) nodes
    for (const [, node] of this.nodes) {
      bounds.left = Math.min(bounds.left, node.x - xStep)
      bounds.right = Math.max(bounds.right, node.x + xStep)
      bounds.top = Math.min(bounds.top, node.y - yStep)
      bounds.bottom = Math.max(bounds.bottom, node.y + yStep)
    }
  }

  const grid = []
  for (let x = bounds.left; x <= bounds.right; x += xStep) {
    for (let y = bounds.top; y <= bounds.bottom; y += yStep) {
      grid.push(xStep == 2 ? { x: x.next(y.even()), y} : {x, y: y.next(x.even())})
    }
  }

  return grid
}

HexModel.prototype.getCenter = function (nodes) {
  // get bounds around active nodes
  const bounds = { left: null, top: null, right: null, bottom: null }
  for (const node of nodes) {
    bounds.left = bounds.left === null ? node.x : Math.min(bounds.left, node.x)
    bounds.top = bounds.top === null ? node.y : Math.min(bounds.top, node.y)
    bounds.right = bounds.right === null ? node.x : Math.max(bounds.right, node.x)
    bounds.bottom = bounds.bottom === null ? node.y : Math.max(bounds.bottom, node.y)
  }

  const center = {x: (bounds.right + this.xStep() + bounds.left) / 2, y: (bounds.bottom + this.yStep() + bounds.top) / 2 }
  this.log(`Center at (${center.x},${center.y})`)
  return center
}

HexModel.prototype.setAngle = function (angle) {
  if (this.angle == angle) return false

  this.log(`Converting from angle ${this.angle}° to ${angle}°`)
  let sourceAngle = this.angle
  let start = Utils.now()
  this.indent++

    // remove empty nodes
  for (const [, node] of this.nodes) if (!node.active) this.remove_(node)

  // we translate so that the previous grid center matches the new grid center, in order to keep the active nodes in the sweet spot of the grid
  const gridCenterBefore = this.getCenter(this.getGrid(true))
  this.angle = angle
  const gridCenterAfter = this.getCenter(this.getGrid(true))

  // convert all coordinates to new angle and reindex nodes
  this.log(`Converting all coordinates from angle ${sourceAngle}° to ${this.angle}°`)
  this.nodesByCoordinate = new Map()
  for (const [, node] of this.nodes) {
    [node.x, node.y] = this.convert(node.x, node.y, sourceAngle, gridCenterBefore, gridCenterAfter)
    this.nodesByCoordinate.set(`${node.x},${node.y}`, node)
  }

  this.indent--
  this.log(`Converted to angle ${this.angle}° in ${Utils.spent(start)}`)

    // add back empty nodes
  this.addEmptyNodes()

  return true
}

HexModel.prototype.snap = function (x, y) {
  if (this.orientation(this.angle) == Hex.POINTY_TOP) {
    y = Math.round(y)
    x = x.closest(y.even())
  } else {
    x = Math.round(x)
    y = y.closest(x.even())
  }
  return [x, y]
}

HexModel.prototype.convert = function (x0, y0, sourceAngle, sourceCenter, destCenter) {
  // source x and y must be either both even or odd
  if (x0.even() != y0.even()) throw Error(`Convert got invalid coordinates (${x0}, ${y0})`)

  // simple convert around (0,0)
  if (!sourceCenter) return this.convert_(x0, y0, sourceAngle)

  // convert around source center followed by a translation to destination center
  const [x, y] = this.convert_(x0 - sourceCenter.x, y0 - sourceCenter.y, sourceAngle)
  return this.snap(x + destCenter.x, y + destCenter.y)
}

// convert coordinates expressed at given angle to current angle
HexModel.prototype.convert_ = function (x0, y0, sourceAngle) {
  let x = 0
  let y = 0

  const destAngle = this.angle + (sourceAngle > this.angle ? 360 : 0)
  const steps = Math.ceil(destAngle / 60) - Math.ceil(sourceAngle / 60)

  if (this.orientation(sourceAngle) == Hex.POINTY_TOP) {
    {
      // first go the the right column via side 2 ou 5
      const side = this.off(x0 > y0 ? 2 : 5, steps)
      const [off_x, off_y] = this.getOffsetXY(side)
      const n = Math.abs((x0 - y0) / 2)
      x += n * off_x
      y += n * off_y
    }

    {
      // then go the the right row via side 3 ou 6
      const side = this.off(y0 > 0 ? 3 : 6, steps)
      const [off_x, off_y] = this.getOffsetXY(side)
      const n = Math.abs(y0)
      x += n * off_x
      y += n * off_y
    }
  } else {
    {
      // first go the the right row via side 4 ou 1
      const side = this.off(y0 > x0 ? 4 : 1, steps)
      const [off_x, off_y] = this.getOffsetXY(side)
      const n = Math.abs((x0 - y0) / 2)
      x += n * off_x
      y += n * off_y
    }

    {
      // then go the the right column via side 3 ou 6
      const side = this.off(x0 > 0 ? 3 : 6, steps)
      const [off_x, off_y] = this.getOffsetXY(side)
      const n = Math.abs(x0)
      x += n * off_x
      y += n * off_y
    }
  }

  return [x, y]
}

HexModel.prototype.getOffsetXY = function (side) {
  if (this.orientation(this.angle) == Hex.POINTY_TOP) {
    switch (side) {
      case 1:
        return [1, -1]
      case 2:
        return [2, 0]
      case 3:
        return [1, 1]
      case 4:
        return [-1, 1]
      case 5:
        return [-2, 0]
      case 6:
        return [-1, -1]
    }
  } else {
    switch (side) {
      case 1:
        return [0, -2]
      case 2:
        return [1, -1]
      case 3:
        return [1, 1]
      case 4:
        return [0, 2]
      case 5:
        return [-1, 1]
      case 6:
        return [-1, -1]
    }
  }

  return [0, 0]
}

HexModel.prototype.orientation = function (angle) {
  return angle.mod(60) == 0 ? Hex.POINTY_TOP : Hex.FLAT_TOP
}

HexModel.prototype.off = function (side, offset) {
  return (side - 1 + offset).mod(6) + 1
}

export default HexModel
