<template>
  <div></div>
</template>

<script>
export default {
  created() {
    const component = this;
    this.handlerup = function (e) {
      component.$emit('keypress', e, 'up');
    }
    this.handlerdown = function (e) {
      component.$emit('keypress', e, 'down');
    }
    window.addEventListener('keydown', this.handlerdown);
    window.addEventListener('keyup', this.handlerup);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handlerdown);
    window.removeEventListener('keyup', this.handlerup);
  }
}
</script>

<style lang="scss" scoped>
div {
  display: none;
}
</style>
