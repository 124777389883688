<template>
  <svg
  pointer-events="all" 
  :width="width"
  :height="height"
  :viewBox="viewbox"
  version="1.0">
  <g :fill="color" transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)">
    <path d="M10495 18038 c-22 -12 -422 -236 -890 -498 -2242 -1258 -3725 -2091
    -4620 -2592 -539 -303 -986 -556 -993 -562 -11 -10 -14 -427 -19 -2217 -3
    -1212 -3 -2225 1 -2251 8 -53 85 -175 259 -408 115 -154 321 -366 497 -511
    921 -759 1984 -1326 2925 -1562 330 -83 583 -114 1000 -124 369 -8 638 17 980
    93 303 68 496 135 760 263 444 217 786 526 965 872 155 299 167 563 37 813
    -97 187 -295 319 -538 357 -98 15 -315 5 -442 -20 -133 -27 -230 -58 -512
    -161 -444 -163 -579 -195 -942 -221 -347 -25 -726 36 -1208 196 -259 85 -348
    121 -580 235 -334 165 -509 278 -688 450 -162 154 -279 311 -372 499 -86 173
    -176 474 -212 711 -25 166 -25 575 0 755 77 542 366 1103 782 1520 234 235
    409 358 855 600 621 338 1136 552 1648 685 159 42 562 118 762 144 385 51 857
    56 1255 15 378 -39 783 -121 1095 -223 376 -122 546 -214 1223 -661 323 -213
    408 -288 656 -575 282 -327 405 -528 505 -830 128 -383 183 -723 173 -1064 -9
    -300 -44 -421 -233 -801 -176 -355 -242 -580 -266 -907 -40 -546 151 -934 561
    -1138 153 -77 317 -112 516 -113 354 0 646 116 999 396 225 179 456 433 473
    521 4 23 5 822 2 1776 -5 1350 -10 1812 -23 2080 -8 190 -16 404 -16 475 -1
    180 -8 212 -60 256 -67 58 85 -35 -3945 2401 -632 382 -1247 752 -1365 823
    -512 305 -911 526 -948 525 -10 -1 -35 -10 -57 -22z m70 -602 c162 -75 4353
    -2492 5015 -2891 575 -347 790 -523 790 -646 0 -14 4 -29 8 -34 5 -6 5 -147 1
    -315 -4 -168 -6 -408 -4 -535 3 -126 7 -448 10 -715 3 -267 10 -834 16 -1260
    8 -586 8 -794 -1 -855 -18 -121 -52 -178 -195 -321 -199 -199 -419 -347 -580
    -390 -82 -22 -237 -29 -306 -15 -120 25 -226 96 -269 181 -40 79 -53 150 -52
    290 1 159 20 287 63 425 35 113 132 320 206 441 54 89 62 109 122 300 90 289
    114 445 114 729 0 402 -65 772 -214 1220 -127 384 -294 658 -646 1062 -276
    317 -400 425 -760 662 -589 386 -670 436 -868 532 -516 249 -1127 403 -1870
    471 -217 20 -838 17 -1060 -5 -313 -31 -754 -106 -1035 -177 -266 -66 -683
    -210 -1003 -346 -394 -166 -1134 -565 -1389 -747 -507 -363 -906 -861 -1163
    -1452 -69 -159 -156 -417 -184 -545 -138 -649 -89 -1303 145 -1908 187 -485
    545 -909 1015 -1201 209 -130 536 -294 778 -390 354 -141 827 -270 1161 -316
    182 -25 495 -30 695 -11 379 36 561 81 1023 252 431 159 643 192 698 108 54
    -82 -92 -333 -300 -516 -336 -294 -786 -468 -1421 -550 -224 -28 -768 -9
    -1040 38 -632 108 -1444 459 -2180 941 -433 284 -851 616 -1100 875 -105 108
    -128 138 -170 222 l-50 98 4 1317 c2 724 6 1600 10 1946 l6 630 27 57 c29 62
    49 79 178 152 41 23 219 132 395 241 432 267 1104 670 1470 880 460 264 1217
    679 1349 740 175 79 2155 1182 2296 1279 57 39 147 75 185 76 17 0 53 -11 80
    -24z"/>
    <path d="M14245 10510 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
    0 -8 -4 -11 -10z"/>
    <path d="M13830 10491 c-110 -34 -174 -119 -174 -231 0 -102 53 -188 146 -234
    162 -81 347 28 348 203 0 83 -19 130 -75 188 -68 69 -164 98 -245 74z"/>
    <path d="M13010 10451 c-110 -34 -174 -119 -174 -231 0 -102 53 -188 146 -234
    162 -81 347 28 348 203 0 83 -19 130 -75 188 -68 69 -164 98 -245 74z"/>
    <path d="M14084 8799 c-54 -16 -128 -72 -161 -122 -42 -63 -63 -134 -63 -217
    0 -246 243 -569 546 -727 164 -86 332 -123 554 -123 281 0 548 69 1020 266
    205 85 392 154 417 154 15 0 16 -35 10 -387 -4 -214 -10 -444 -14 -511 l-6
    -124 -40 -25 c-80 -51 -325 -188 -777 -435 -256 -141 -543 -299 -638 -352
    -175 -98 -542 -297 -836 -453 -278 -148 -1205 -660 -1776 -980 -441 -248 -846
    -462 -1185 -629 -170 -83 -318 -161 -328 -172 -10 -11 -30 -53 -45 -94 -37
    -104 -37 -229 0 -308 31 -66 93 -140 117 -140 17 0 304 152 1221 645 365 196
    705 377 1410 752 836 445 1425 759 1980 1056 1568 838 1455 776 1465 814 3 14
    3 116 0 226 -3 111 -10 490 -15 842 -6 352 -13 669 -16 704 -5 56 -10 68 -45
    106 -21 23 -65 56 -97 73 -210 112 -450 78 -982 -141 -364 -150 -521 -202
    -689 -231 -306 -52 -504 34 -631 274 -59 110 -141 199 -217 233 -68 30 -135
    40 -179 26z"/>
    <path d="M4035 8274 c-22 -8 -46 -22 -52 -31 -10 -13 -13 -194 -13 -827 0
    -446 4 -816 8 -822 4 -7 543 -321 1197 -699 2400 -1387 3075 -1781 3767 -2196
    246 -148 456 -269 466 -269 43 0 315 134 343 168 10 13 28 49 40 80 18 49 22
    81 24 222 2 151 5 171 29 234 108 282 422 519 1206 911 626 313 938 496 1285
    755 403 300 670 598 847 946 114 223 219 575 258 861 25 189 -52 372 -190 447
    -44 24 -58 27 -98 21 -118 -17 -234 -111 -296 -237 -23 -48 -44 -116 -61 -198
    -106 -514 -220 -745 -515 -1040 -222 -222 -533 -448 -925 -670 -60 -34 -270
    -144 -465 -243 -593 -301 -846 -446 -1063 -608 -207 -154 -338 -288 -453 -460
    -70 -106 -108 -183 -149 -306 -18 -56 -32 -83 -42 -83 -32 0 -495 255 -961
    529 -276 162 -496 287 -1097 626 -740 418 -1073 616 -1705 1010 -769 481 -885
    555 -897 573 -9 13 -9 151 -1 601 10 569 10 584 -9 610 -30 41 -108 88 -163
    100 -73 16 -266 13 -315 -5z"/>
    <path d="M11430 7870 c-69 -25 -161 -88 -277 -188 -49 -42 -152 -131 -229
    -197 -300 -260 -538 -419 -809 -543 -561 -255 -1125 -273 -1825 -56 -166 52
    -410 144 -870 329 -223 89 -469 185 -548 214 -330 119 -639 197 -881 222 -142
    15 -427 6 -531 -16 -312 -66 -499 -340 -375 -551 45 -77 144 -133 249 -141 52
    -4 79 1 176 33 114 38 117 38 270 38 335 -2 609 -78 1355 -379 645 -260 910
    -355 1200 -430 1005 -258 1877 -78 2730 565 72 54 193 153 270 220 77 66 203
    174 280 239 158 133 206 193 231 290 36 142 -22 272 -151 339 -69 35 -187 41
    -265 12z"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'Octopuss',
  props: {
    width: Number,
    height: Number,
    viewbox: {
      type: String,
      default: "0 0 2048 2048"
    },
    color: String
  },
  setup() {
    return {}
  }
}
</script>

<style lang="scss">
</style>
