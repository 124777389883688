<template>

  <Keypress @keypress="onKeyPress"></Keypress>

  <div class="toolbar">
    <input id="file-trigger" type="file" style="display:none" @change="loadFile()" />
    <span class="title"><i class="fas fa-hexagon" :style="{'--angle' : state.angle + 'deg'}" @click="state.angle = (state.angle + 30).mod(360)" @click.right.prevent="state.angle = (state.angle - 30).mod(360)"></i><span>Hexx it!</span></span>
    <span class="subtitle">Let's think around Hexx, baby</span>
  </div>

  <DockMenu :items="menuItems" :key="state.menuRefresh" :on-selected="handleMenu" :theme="{
    primary: '#001B48',
    secondary: '#203d6f',
    tertiary: '#018abe',
    textColor: '#fff',
    textHoverColor: '#fff' }">
    <template #undo><i class="fa fa-undo"></i></template>
    <template #redo><i class="fa fa-redo"></i></template>
    <template #remove><i class="fa fa-times"></i></template>
    <template #eraser><i class="fa fa-eraser"></i></template>
    <template #new><i class="fa fa-file"></i></template>
    <template #open><i class="fa fa-folder-open"></i></template>
    <template #save><i class="fa fa-save"></i></template>
    <template #download><i class="fa fa-download"></i></template>
    <template #upload><i class="fa fa-upload"></i></template>
    <template #check><i class="fa fa-check"></i></template>
    <template #empty><i class="fa"></i></template>
    <template #preferences><i class="fa fa-cog"></i></template>
    <template #logout><i class="fa fa-sign-out"></i></template>
    <template #login><i class="fa fa-sign-in"></i></template>
    <template #about><i class="fa fa-info-circle"></i></template>
    <template #logged><img :style="{width:'20px'}" :src="$auth.user.value.picture"></template>
  </DockMenu>

  <GDialog
  v-model="state.open_document_dialog"
  max-width="600"
  local
  >
  <div class="dialog">

    <div class="content">
      <div class="title">Your saved hexamaps</div>
      <div class="message" v-if="state.documents.length === 0">
        <p>You don't have any document saved yet.</p>
        <p>Use "File > Save As..." to save a document.</p>
      </div>

      <div class="doclist">
        <select size=10 v-model="state.openDocument" @dblclick="state.open_document_dialog = false; loadFromApi(state.openDocument._id)">
          <option v-for="document of state.documents" :key="document._id" :value="document">
            {{document.name}}
            <i :style="{float:'right'}" :class="`fa ${document.public ? 'fa-users': 'fa-key'}`"></i>
          </option>
        </select>
      </div>

    </div>

    <div class="actions">
      <button class="btn-dialog" @click="state.open_document_dialog = false">Cancel</button>
      <button class="btn-dialog red" :disabled="!state.openDocument" @click="deleteDocument(state.openDocument._id, state.openDocument.name)">Delete</button>
      <button class="btn-dialog blue" :disabled="!state.openDocument" @click="renameDocument(state.openDocument._id, state.openDocument.name)">Rename</button>
      <button class="btn-dialog blue" :disabled="!state.openDocument" @click="updateDocument(state.openDocument._id, { public: !state.openDocument.public })">Make {{state.openDocument && state.openDocument.public ? 'private' : 'public'}}</button>
      <button class="btn-dialog green" :disabled="!state.openDocument" @click="state.open_document_dialog = false; loadFromApi(state.openDocument._id)">Open</button>
    </div>

  </div>
</GDialog>

<GDialog
v-model="state.error_dialog"
max-width="500"
local
>
<div class="dialog">

  <div class="content">
    <div class="title">Error</div>
    <div class="error message">
      <p v-for="message of state.errorMessages" :key="message">{{message}}</p>
    </div>
  </div>

  <div class="actions">
    <button class="btn-dialog" @click="state.error_dialog = false">Close</button>
    <button class="btn-dialog" @click="state.error_dialog = false; errorButton(button.key)" v-for="button of state.errorButtons" :key="button.key">{{button.label}}</button>
  </div>

</div>
</GDialog>

<div class="main"
:draggable="!hexagrid || !hexagrid.state.edit"
@dragstart.prevent="startDrag($event)"
@mousemove="onMouseMove($event)"
@click="onClick($event)"
>

<div class="select-mask" v-if="state.smask" :style ="{ top: state.smask.top + 'px', left: state.smask.left + 'px', width: state.smask.w + 'px', height: state.smask.h + 'px'}"/>

<div class="grid">
  <Hexagrid ref="hexagrid"
  @init="onGridInit"
  @load="onGridLoad"
  @select="onGridSelect"
  @focus="onGridFocus"
  @change="onGridChange"
  :size="state.size"
  :gutter="state.gutter"
  :angle="state.angle"
  :radius="state.radius"
  :useGrid="state.useGrid"
  :gridWidth="state.gridWidth"
  :gridHeight="state.gridHeight"
  :autoGrow="state.autoGrow"
  :locked="state.locked"
  :sounds="state.sounds"
  :legacyPalette="state.legacyPalette"
  :customDragImage="customDragImage"
  :debug="debug" />
</div>

<div class="statusbar">

  <a :disabled="state.zoom <= minZoom" @click="decZoom()"><i class="fa fa-search-minus"></i></a>
  <a class="between" title="Click to auto-fit" @click="autoZoom()">{{state.zoom}}%</a>
  <a :disabled="state.zoom >= maxZoom" @click="incZoom()"><i class="fa fa-search-plus"></i></a>

  <a @click="incGutter()" @click.right.prevent="decGutter()"><i class="fa fa-grip-lines" :gutter="state.gutter"></i></a>
  <a @click="state.sounds = !state.sounds"><i :class="`fa fa-${state.sounds ? 'volume-up' : 'volume-mute'}`"></i></a>
  <a @click="state.legacyPalette = !state.legacyPalette"><i :class="`fa fa-rainbow ${state.legacyPalette ? 'off' : 'on'}`"></i></a>

  <div class="grid-options" :style="{visibility:state.locked < 2 ? 'visible' : 'hidden'}">

    <input type="checkbox" v-model="state.useGrid" /><label>Grid</label>

    <a v-if="state.locked < 2 && !state.useGrid && state.count > 0"
      @click="state.radius = (state.radius + 1).mod(3)"
      @click.right.prevent="state.radius = (state.radius - 1).mod(3)"><i class="fa fa-wifi" :radius="state.radius"></i>
    </a>

    <div :style="{display: 'inline-block', visibility: state.locked < 2 && state.useGrid ? 'visible' : 'hidden'}">
      <input type="number" min="1" v-model.number="state.gridWidth" /><i class="fa fa-times"></i>
      <input type="number" min="1" v-model.number="state.gridHeight" />
      <a v-if="state.count > 0" title="Center hexamap in grid" @click="centerGrid"><i class="far fa-bullseye"></i></a>
      <input type="checkbox" v-model="state.autoGrow" /><label>Auto-grow</label>
    </div>

  </div>

  <div class="right">
    <i class="last-save">{{state.lastSavedMessage}}</i>
    <i>{{state.angle}}°</i>
    <i>{{state.count}}</i>
  </div>

</div>

</div>
</template>

<script>
import Keypress from '@/components/Keypress.vue'
import Hexagrid from '@/components/Hexagrid.vue'
import { reactive, ref, watch, inject, computed, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { detect } from 'detect-browser'
import { DockMenu } from 'vue-dock-menu'
import authConfig from '../../auth_config.json'
import '@fortawesome//fontawesome-free/css/fontawesome.min.css'
import '@fortawesome//fontawesome-free/css/solid.min.css'
import '@fortawesome//fontawesome-free/css/regular.min.css'
import "vue-dock-menu/dist/vue-dock-menu.css";

export default {
  name: 'Home',
  components: {
    Keypress,
    Hexagrid,
    DockMenu
  },
  props: {
    baseSize: {
      type: Number,
      default: 150
    },
    minZoom: {
      type: Number,
      default: 10
    },
    maxZoom: {
      type: Number,
      default: 400
    },
    debug: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {

    const route = useRoute()
    const router = useRouter()
    const auth = inject('auth')

    const defaults = {
      locked: 0,
      sounds: true,
      legacyPalette: true,
      zoom: 100,
      lastManualZoom: 100,
      size: props.baseSize,
      gutter: 3,
      angle: 0,
      radius: 1,
      useGrid: false,
      gridWidth: 7,
      gridHeight: 5,
      autoGrow: true,
      count: 0,
      filename: null,
      menuRefresh: 0,
      dialog: false,
      lastSavedMessage: ''
    }

    const checkZoom = zoom => {
      return Math.min(Math.max(parseInt(zoom, 10) || defaults.zoom, props.minZoom), props.maxZoom)
    }

    const result = detect()
    const browser = result ? result.name : 'unknown'
    console.log('Detected browser :', browser)
    const customDragImage = true // browser == 'chrome'

    // load or init state
    let savedState = localStorage.getItem('Home.state')
    savedState = savedState ? JSON.parse(savedState) : {}
    if (typeof savedState['locked'] == 'boolean') savedState['locked'] = savedState['locked'] ? 2 : 0 // convert old boolean to integer (0,1,2)
    if (typeof savedState['lastManualZoom'] == 'undefined') savedState['lastManualZoom'] = savedState['zoom'] // if saved state contains zoom but not lastManualZoom yet, init lastManualZoom equal to zoom
    for (let p in defaults) if (typeof savedState[p] == 'undefined' || p == 'count' || p == 'lastSavedMessage') savedState[p] = defaults[p]
    savedState.zoom = checkZoom(savedState.zoom)
    savedState.lastManualZoom = checkZoom(savedState.lastManualZoom)
    const state = reactive(savedState)

    // get _id from url, if it's a valid one
    state._id = route.params.id ? route.params.id : null

    const hexagrid = ref(null)

    // save when change radius or angle
    watch(
      [
        () => state.zoom,
        () => state.radius,
        () => state.useGrid,
        () => state.gridWidth,
        () => state.gridHeight,
        () => state.autoGrow,
        () => state.angle,
        () => state.locked,
        () => state.gutter,
        () => state.filename
      ],
      () => {
        save()
      }
    )

    // recreate menu when needed (does not happen automatically)
    watch(
      [
        () => state.locked
      ],
      () => {
        state.menuRefresh++
      }
    )

    // watch when route changes
    watch(
      route,
      () => {
        const new_id = route.params.id ? route.params.id : null
        if (state._id !== new_id) {
          state._id = new_id
          if (!state._id) backToLocal()
          onGridInit()
        }

      }
    )

    const updateSelectMask = (event) => {

      if (event.x >= state.smask.x) {
        state.smask.w = event.x - state.smask.x
        state.smask.left = state.smask.x
      }
      else {
        state.smask.w = state.smask.x - event.x
        state.smask.left = event.x
      }

      if (event.y >= state.smask.y) {
        state.smask.h = event.y - state.smask.y
        state.smask.top = state.smask.y
      }
      else {
        state.smask.h = state.smask.y - event.y
        state.smask.top = event.y
      }

      if (props.debug) console.log(`Updated select mask dimensions ${JSON.stringify(state.smask)}`)
    }

    const startDrag = (event) => {
      state.smask = { x: event.x, y: event.y }
      updateSelectMask(event)
    }

    const onMouseMove = (event) => {
      if (state.smask) updateSelectMask(event)
    }

    const onClick = (event) => {
      if (state.smask) {

        const metaKey = event.ctrlKey || event.shiftKey || event.metaKey

        // get selected hexagon elements
        let nodeIds = []
        for (let element of document.getElementsByClassName('hexagon')) {

          // ignore inactive hexagons
          if (element.getAttribute('active') === "false") continue;

          // add element if entirely contained in select mask
          let rect = element.getBoundingClientRect()
          if (rect.left >= state.smask.left
            && rect.top >= state.smask.top
            && rect.left + rect.width <= state.smask.left + state.smask.w
            && rect.top + rect.height <= state.smask.top + state.smask.h
          ) {
            nodeIds.push(element.getAttribute('nodeId'))
          }
        }

        // remove mask
        state.smask = null

        // apply selection
        if (nodeIds.length) {
          console.log(`Selected ${nodeIds.length} hexagons${metaKey ? ' with meta key: appending to selection' : ': setting selection'}`)
          if (metaKey) hexagrid.value.appendSelection(nodeIds)
          else hexagrid.value.setSelection(nodeIds)
        }
        else {
          console.log(`Selected nothing${metaKey ? ' with meta key: doing nothing' : ': clearing selection'}`)
          if (!metaKey) hexagrid.value.clearSelection()
        }

        // inhibit click on body set by ClickOutsideDirective
        event.stopPropagation()
      }
    }

    const onGridSelect = () => {

      // update state of menu items
      state.menuRefresh++

      // clear focus if any
      hexagrid.value.clearFocus()
    }

    const onGridFocus = (target) => {

      // if we used on auto-fit, revert to last manually set zoom (if greater than current zoom)
      if (state.lastManualZoom > state.zoom) setZoom(state.lastManualZoom)

      // wait ui update then scroll main div so that the hexagon is centered (if possible)
      nextTick(() => {

        // get main div coordinates (relative to viewport)
        let main = document.getElementsByClassName('main')[0]
        let mainrect = main.getBoundingClientRect()

        // get hexagon center coordinates (relative to viewport)
        let rect = target.getBoundingClientRect()
        let centerX = rect.x + rect.width / 2
        let centerY = rect.y + rect.height / 2

        // get coordinates (relative to viewport) of the point that must be set top left so that center is in the center
        let topleftX = centerX - mainrect.width / 2
        let topleftY = centerY - mainrect.height / 2

        // convert these coordinates into the coordinates system of the main scrollable zone
        main.scrollTo(topleftX + main.scrollLeft - mainrect.x , topleftY + main.scrollTop - mainrect.y)
      })
    }

    const onGridChange = (model, json) => {
      state.count = model.count()
      state.menuRefresh++

      if (json) {
        if (state._id) saveToApi(state._id, json)
        else {

          // save state
          console.log('Saving to local storage')
          localStorage.setItem('Hexagrid.state', json)
        }
      }
    }

    const incZoom = () => {
      let step = state.zoom < 250 ? (state.zoom < 150 ? (state.zoom < 100 ? 5 : 10) : 25) : 50
      setZoom(Math.round((state.zoom + step) / step) * step)
      state.lastManualZoom = state.zoom
    }

    const decZoom = () => {
      let step = state.zoom > 250 ? 50 : state.zoom > 150 ? 25 : state.zoom > 100 ? 10 : 5
      setZoom(Math.round((state.zoom - step) / step) * step)
      state.lastManualZoom = state.zoom
    }

    const resetZoom = () => {
      setZoom(100)
      state.lastManualZoom = state.zoom
    }

    const autoZoom = () => {

      // get size of main div and of grid div
      // grid div size will be bigger than main div if there are scrollbars and smaller otherwise
      // don't use main.scrollWidth and scrollHeight because these will never be smaller than mainrect.width and height (i.e. we would not detect when the zoom must be increased)
      let mainrect = document.getElementsByClassName('main')[0].getBoundingClientRect()
      let gridrect = document.getElementsByClassName('grid')[0].getBoundingClientRect()

      // compute zoom for auto fit
      let xFactor = mainrect.width / gridrect.width
      let yFactor = mainrect.height / gridrect.height
      let factor = Math.min(xFactor, yFactor)

      // floor to closest 5% and set
      setZoom(Math.floor(Math.round(state.zoom * factor) / 5) * 5)
    }

    const setZoom = zoom => {

      let prevSize = state.size

      // get main div center coordinates
      let main = document.getElementsByClassName('main')[0]
      let prevCenter = { x : main.scrollLeft + main.offsetWidth / 2, y : main.scrollTop + main.offsetHeight / 2 }

      // check and apply zoom
      let checkedZoom = checkZoom(zoom)
      if (checkedZoom !== zoom) {
        if (checkedZoom === state.zoom) return true
        console.warn(`Tried to set invalid zoom ${zoom}: setting zoom to ${checkedZoom}`)
        zoom = checkedZoom
      }
      state.zoom = checkedZoom
      state.size = Math.round((checkedZoom / 100) * props.baseSize)

      // wait ui update then scroll main div so that previously centered point stays in the center (if possible)
      nextTick(() => {

        // apply zoom factor to the center coordinates
        let f = state.size / prevSize
        let center = { x: prevCenter.x * f, y: prevCenter.y * f }

        // get coordinates of the point that must be set top left so that center is in the center
        let topleft = { x: Math.max(0, center.x - main.offsetWidth / 2), y: Math.max(0, center.y - main.offsetHeight / 2) }
        main.scrollTo(topleft.x, topleft.y)
      })
    }

    const incGutter = () => {
      let step = state.gutter < 50 ? (state.gutter < 20 ? (state.gutter < 10 ? 1 : 2) : 5) : 10
      state.gutter = Math.round((state.gutter + step) / step) * step
      if (state.gutter > 50) state.gutter = 0
    }

    const decGutter = () => {
      let step = state.gutter > 50 ? 10 : state.gutter > 20 ? 5 : state.gutter > 10 ? 2 : 1
      state.gutter = Math.round((state.gutter - step) / step) * step
      if (state.gutter < 0) state.gutter = 50
    }

    const centerGrid = () => {
      if (state.locked < 2 && state.count > 0) {
        hexagrid.value.center()
      }
    }

    const download = () => {
      if (state.count > 0) {
        let filename = prompt('Enter filename', state.filename || '')
        if (filename) {
          if (!filename.endsWith('.json')) filename += '.json'
          hexagrid.value.download(filename)
          state.filename = filename
        }
      }
    }

    const openFilechooser = () => {
      document.getElementById('file-trigger').click()
    }

    const errorButton = (buttonkey) => {
      if (buttonkey == "reload") {
        loadFromApi(state._id)
      }
    }

    const errorMsg = (messages, buttons) => {
      state.errorMessages = messages
      state.errorButtons = buttons || []
      state.error_dialog = true
    }

    const loadFile = async () => {
      const input = document.getElementById('file-trigger')

      // go back to local workspace (reload from local storage)
      if (state._id) await backToLocal()

      if (state.count == 0 || confirm('Loading a file will remove all existing hexagons. Please confirm.')) {
        state.locked = 0
        if (state.radius == 0) state.radius = 1
        state.filename = null
        hexagrid.value.loadFile(input.files[0])
      }
      input.value = '' // chrome does not fire event again when selecting the same file
    }

    const onGridLoad = (filename, model, json) => {
      if (filename) console.log(`File has been loaded: update filename ${filename} and angle ${model.angle}°`)
      else console.log(`Document has been loaded: update angle ${model.angle}°`)
      // model has been loaded with its saved angle, so we update ours to match
      state.angle = model.angle
      if (filename) state.filename = filename
      onGridChange(model,json)
    }

    const save = () => {
      // save state
      localStorage.setItem('Home.state', JSON.stringify(state))
    }

    const onGridInit = async () => {

      // load document if an id has been given in url
      if (state._id) loadFromApi(state._id)

      // otherwise load from local storage
      else {

        const json = localStorage.getItem('Hexagrid.state')
        if (json) {
          console.log(`Loading from local storage`)
          await nextTick()
          hexagrid.value.loadJSON(json)
        }
        else console.log(`Nothing to load from local storage`)

      }
    }

    const afterSaved = async result => {

      const document = await result.json()

      if (result.status !== 200 || !document || document.error) {
        const message = document && document.error ? document.error : 'unknown error'
        console.error(`Error ${result.status} while saving document: ${message}`)
        if (result.status === 404) errorMsg([message])
        return false
      }

      if (state._id != document._id) {
        state._id = document._id
        router.push('/' + state._id)
        connectToStream()
      }

      state.revision = document.revision
      state.lastSavedMessage = `last save: ${(new Date()).toLocaleTimeString()}`
      console.log(`Saved document with id ${state._id} revision "${document.revision}"`)
    }

    const saveToApi = async (_id, json, name) => {

      const savingLocalWorkspace = !state._id

      const accessToken = auth.isAuthenticated.value ? await auth.getTokenSilently() : null
      let requestBody = { contents: json }
      if (name) requestBody.name = name
      if (!_id) requestBody.public = false
      else requestBody.revision = state.revision
      const body = JSON.stringify(requestBody)
      const headers = { 'Content-Type': 'application/json' }
      if (accessToken) headers.Authorization = 'Bearer ' + accessToken

      if (_id) {

        console.log('PUT to ' + authConfig.api_uri)
        const result = await fetch(authConfig.api_uri + '/documents/' + _id, { method: 'PUT', body, headers })
        afterSaved(result)

        if (result.status === 403) errorMsg([`You are not authorized to update this document.`, `You can use 'File > Save As...' to save as a new document.`])
        if (result.status === 409) errorMsg([`You are working on an outdated document.`, `You can reload from server or ignore and use 'File > Save As...' to save as a new document.`], [{ key: "reload", label: "Reload"}])
      }
      else {
        if (!requestBody.name) throw new Error('A document name is mandatory for posting to the api')

        console.log('POST to ' + authConfig.api_uri)
        const result = await fetch(authConfig.api_uri + '/documents/', { method: 'POST', body, headers })
        afterSaved(result)

        // after sucessfully creating a new document from the local space, we can clear local storage
        if (savingLocalWorkspace) localStorage.removeItem('Hexagrid.state')
      }
    }

    const deleteDocument = async (_id, name) => {

      if (confirm(`Are you sure you want to permanently delete document "${name}" ?`)) try {
        const accessToken = await auth.getTokenSilently()
        const result = await fetch(authConfig.api_uri + '/documents/' +_id, {
          method: 'DELETE',
          headers: {
            Authorization: 'Bearer ' + accessToken
          }
        })
        const document = await result.json()
        if (result.status !== 200 || !document || document.error) {
          const message = document && document.error ? document.error : 'unknown error'
          console.error(`Error ${result.status} while deleting document with id ${_id}: ${message}`)
        }
        else {
          console.log(`Deleted document with id ${_id}`)
          listDocuments()
        }

      } catch (e) {
        const message = e.message
        console.error(`Error ${result.status} while deleting document with id ${_id}: ${message}`)
      }

    }

    const updateDocument = async (_id, updatedFields) => {

      try {

        const accessToken = await auth.getTokenSilently()
        const body = JSON.stringify(updatedFields)
        const headers = {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json'
        }

        console.log('PUT to ' + authConfig.api_uri)
        const result = await fetch(authConfig.api_uri + '/documents/' + _id, { method: 'PUT', body, headers })
        if (result.status !== 200 || !document || document.error) {
          const message = document && document.error ? document.error : 'unknown error'
          console.error(`Error ${result.status} while updating document with id ${_id}: ${message}`)
        }
        else {
          console.log(`Updated document with id ${_id}`)
          listDocuments()
        }


      } catch (e) {
        const message = e.message
        console.error(`Error ${result.status} while updating document with id ${_id}: ${message}`)
      }

    }

    const renameDocument = async (_id, name) => {
      let filename = prompt('Enter a name for your document', name)
      if (filename) return updateDocument(_id, { name: filename })
    }

    const backToLocal = async () => {
      state._id = null
      router.push('/')
      state.revision = null
      state.lastSavedMessage = null
      if (state.source && state.source.close) {
        console.log("Closing connection to notification stream")
        state.source.close()
      }

      // reload from local storage
      const json = localStorage.getItem('Hexagrid.state')
      if (json) hexagrid.value.loadJSON(json)
      else hexagrid.value.clear()

      // if the local workspace is not empty, display it now before the confirm dialog
      if (state.count > 0) {
        await nextTick()
        await new Promise((resolve) => setTimeout(resolve, 25))
      }
    }

    const connectToStream = async () => {

      if (state.source && state.source.close) {
        console.log("Closing connection to notification stream")
        state.source.close()
      }

      const accessToken = auth.isAuthenticated.value ? await auth.getTokenSilently() : null

      // connect to event stream for current document
      console.log('Connecting to notification stream for document ' + state._id)
      state.source = new EventSource(authConfig.api_uri + '/events/' + state._id + (accessToken ? '?token=' + accessToken : ''));
      state.source.addEventListener('message', message => {
        if (hexagrid.value) {
          const document = JSON.parse(message.data)
          state.revision = document.revision
          if (hexagrid.value.getJSON() !== document.contents) {
            console.log(`Reloading updated document with revision "${document.revision}" from stream`)
            hexagrid.value.loadJSON(document.contents)
          }
        }
      })
    }

    const loadFromApi = async (_id) => {

      // if (!auth.isAuthenticated.value) {
      //  errorMsg([`You must be logged in to open a document.`, `You can use 'File > Sign In' to log in or create an account.`])
      //  backToLocal()
      //  return false
      // }

      state._id = _id

      try {

        const accessToken = auth.isAuthenticated.value ? await auth.getTokenSilently() : null
        const result = await fetch(authConfig.api_uri + '/documents/' + state._id, {
          method: 'GET',
          headers: accessToken ? { Authorization: 'Bearer ' + accessToken } : {}
        })
        const document = await result.json()
        if (result.status !== 200 || !document || document.error) {
          const message = document && document.error ? document.error : 'unknown error'
          console.error(`Error ${result.status} while loading document with id ${state._id}: ${message}`)
          if (result.status === 404) errorMsg([message])
          backToLocal()
        }
        else {
          console.log(`Loaded document with id ${state._id} revision "${document.revision}"`)
          router.push('/' + state._id)
          state.revision = document.revision
          state.lastSavedMessage = null
          hexagrid.value.loadJSON(document.contents)
          if (document.name) state.filename = document.name
          connectToStream()
        }

      } catch (e) {
        const message = e.message
        console.error(`Error while loading document with id ${state._id}: ${message}`)
        backToLocal()
      }
    }

    const newDocument = async () => {

      // go back to local workspace (reload from local storage)
      if (state._id) await backToLocal()

      if (state.count == 0 || confirm('This will remove all hexagons. Please confirm.')) {
        state.locked = 0
        hexagrid.value.clear()
        if (state.radius == 0) state.radius = 1
        state.filename = null
      }
    }

    const saveAs = () => {
      if (state.filename && state.filename.endsWith('.json')) state.filename = state.filename.replace(/.json$/, '')
      let filename = prompt('Enter a name for your document', state.filename || '')
      if (filename) {
        state.filename = filename
        saveToApi(null, hexagrid.value.getJSON(), filename)
      }
    }

    const listDocuments = async () => {

      try {

        // load documents whose I'm the author
        const accessToken = await auth.getTokenSilently()
        const result = await fetch(authConfig.api_uri + '/documents/', {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + accessToken
          }
        })
        const documents = await result.json()
        if (result.status !== 200 || !documents || documents.error) {
          const message = documents && documents.error ? documents.error : 'unknown error'
          console.error(`Error ${result.status} while listing documents: ${message}`)
        }
        else {
          console.log(`Listed ${documents.length} documents`)
          state.openDocument = null
          state.documents = documents.reverse()
          state.open_document_dialog = true
        }

      } catch (e) {
        const message = e.message
        console.error(`Error ${result.status} while listing documents: ${message}`)
      }

    }

    const handleMenu = (selected) => {
      if (selected.path === "file>new") newDocument()
      else if (selected.path === "file>your saved hexamaps...") listDocuments()
      else if (selected.path === "file>upload hexamap...") openFilechooser()
      else if (selected.path === "file>save as...") saveAs()
      else if (selected.path === "file>download file...") download()
      else if (selected.path === "edit>undo") hexagrid.value.undo()
      else if (selected.path === "edit>redo") hexagrid.value.redo()
      else if (selected.path === "edit>delete") hexagrid.value.removeSelected()
      else if (selected.path === "mode>edition") state.locked = 0
      else if (selected.path === "mode>presentation") state.locked = 1
      else if (selected.path === "mode>locked") state.locked = 2
      else if (selected.path === "file>sign in") auth.loginWithRedirect()
      else if (selected.path === "file>sign out") auth.logout({ returnTo: authConfig.redirect_uri })
      else if (auth.user.value && selected.path.match(new RegExp('file>'+auth.user.value.name, 'i'))) {
        // clicked on my name
        // router.push('/profile');
      }
    }

    const menuItems = computed(() => {
      const menuItems = [
        {
          name: "file",
          menu: [
            {
              name: "New",
              iconSlot: "new"
            },
            {
              name: "Your saved hexamaps...",
              iconSlot: "open",
              disable: !auth.isAuthenticated.value
            },
            {
              name: "Upload hexamap...",
              iconSlot: "upload"
            },
            {
              isDivider: true
            },
            /*
            {
            name: "Preferences",
            iconSlot: "preferences"
          },
          {
          isDivider: true
        },
        */
        {
          name: "Save As...",
          iconSlot: "save",
          disable: !auth.isAuthenticated.value || state.count === 0
        },
        {
          name: "Download File...",
          iconSlot: "download",
          disable: state.count === 0
        },
        {
          isDivider: true
        },
        {
          name: auth.isAuthenticated.value ? "Sign out" : "Sign in",
          iconSlot: auth.isAuthenticated.value ? "logout" : "login",
          disable: auth.loading.value
        }
      ]
    },
    {
      name: "edit",
      menu: [
        {
          name: "Undo",
          iconSlot: "undo",
          disable: !hexagrid.value || !hexagrid.value.hasUndo() || state.locked === 2
        },
        {
          name: "Redo",
          iconSlot: "redo",
          disable: !hexagrid.value || !hexagrid.value.hasRedo() || state.locked === 2
        },
        {
          isDivider: true
        },
        {
          name: "Delete",
          iconSlot: "remove",
          disable: !hexagrid.value || !hexagrid.value.hasSelection() || state.locked > 0
        }
      ]
    },
    {
      name: "mode",
      menu: [
        {
          name: "Edition",
          iconSlot: state.locked === 0 ? "check" : "empty",
          disable: false
        },
        {
          name: "Presentation",
          iconSlot: state.locked === 1 ? "check" : "empty",
          disable: state.count == 0
        },
        {
          name: "Locked",
          iconSlot: state.locked === 2 ? "check" : "empty",
          disable: state.count == 0
        }
      ]
    }
  ]

  if (auth.isAuthenticated.value) {
    // menuItems[0]['menu'].splice(0, 0, { isDivider: true })
    menuItems[0]['menu'].splice(menuItems[0]['menu'].length - 1, 0, {
      name: auth.user.value.name,
      iconSlot: "logged",
      disable: false
    })
  }

  return menuItems
})

const onKeyPress = (event, type) => {

  const key = event.key;
  const keyCode = event.keyCode || event.charCode;
  const Ctrl = event.ctrlKey || event.metaKey
  const Shift = event.shiftKey

  if (key === "Control" || key === "Shift") return true

  if (props.debug) console.log(`Pressed ${type} ${Ctrl ? 'Ctrl-' : ''}${Shift ? 'Shift-' : ''}${key} ${keyCode}`)

  if (hexagrid.value && hexagrid.value.state.edit) {
    if (props.debug) console.log('Ìgnore keypress in edition mode')
    return true
  }

  // Ctrl-A
  if (Ctrl && key === 'a') {
    event.stopPropagation()
    event.preventDefault()
    if (type === 'down') autoZoom()
  }

  // Ctrl-+
  if (Ctrl && key === '+') {
    event.stopPropagation()
    event.preventDefault()
    if (type === 'down') incZoom()
  }

  // Ctrl--
  if (Ctrl && key === '-') {
    event.stopPropagation()
    event.preventDefault()
    if (type === 'down') decZoom()
  }

  // Ctrl--
  if (Ctrl && key === '0') {
    event.stopPropagation()
    event.preventDefault()
    if (type === 'down') resetZoom()
  }
}

return {
  state,
  defaults,
  hexagrid,
  onKeyPress,
  startDrag,
  onMouseMove,
  onClick,
  onGridInit,
  onGridSelect,
  onGridFocus,
  onGridChange,
  incZoom,
  decZoom,
  autoZoom,
  incGutter,
  decGutter,
  centerGrid,
  loadFile,
  onGridLoad,
  customDragImage,
  menuItems,
  handleMenu,
  loadFromApi,
  deleteDocument,
  renameDocument,
  updateDocument,
  errorButton
}
}
}
</script>

<style scoped lang="scss">
@import '../styles/Home';
</style>
