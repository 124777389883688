<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link v-if="$auth.isAuthenticated.value" to="/profile">Profile</router-link>
  </div>
  <router-view/>
</template>

<style lang="scss">
@import "@/styles/App";
</style>
