import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import authConfig from '../auth_config.json'
import { setupAuth } from './auth'

import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'

const FirstTouchDirective = {

  beforeMount: function (el, binding) {
    el.onFirstTouch = function (event) {
      binding.value(event)
      window.removeEventListener('touchstart', el.onFirstTouch)
    }

    window.addEventListener('touchstart', el.onFirstTouch)
  },
  unmounted: function (el) {
    window.removeEventListener('touchstart', el.onFirstTouch)
  }
}

const ClickOutsideDirective = {

  beforeMount: function (el, binding) {
    el.clickOutsideEvent = function (event) {
      // check that click was outside el and his children
      if (!(el == event.target || el.contains(event.target))) binding.value(event)
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}

const app = createApp(App)

app.component('GDialog', GDialog)
app.directive('click-outside', ClickOutsideDirective)
app.directive('first-touch', FirstTouchDirective)
app.use(router)

// install authentication plugin
function callbackRedirect (appState) {
  router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  )
}

setupAuth(authConfig, callbackRedirect).then((auth) => {
  app.use(auth).mount('#app')
})
