import Color from 'color'

// Taken from https://codepen.io/wvr/pen/WrNgJp

/**
 * Vector
 */

function Vector (x, y) {
  this.x = x
  this.y = y
}

Vector.fromAngle = function (angle, magnitude) {
  if (typeof magnitude !== 'number') {
    magnitude = 1
  }
  return new Vector(
    magnitude * Math.cos(angle),
    magnitude * Math.sin(angle)
  )
}

Vector.prototype.add = function (v) {
  return new Vector(this.x + v.x, this.y + v.y)
}
Vector.prototype.subtract = function (v) {
  return new Vector(this.x - v.x, this.y - v.y)
}
Vector.prototype.multiply = function (s) {
  return new Vector(this.x * s, this.y * s)
}
Vector.prototype.divide = function (s) {
  return new Vector(this.x / s, this.y / s)
}
Vector.prototype.magnitude = function () {
  return Math.sqrt(this.x * this.x + this.y * this.y)
}
Vector.prototype.unit = function () {
  return this.divide(this.magnitude())
}
Vector.prototype.angle = function () {
  return Math.atan2(this.y, this.x)
}
Vector.prototype.toString = function () {
  return '(' + this.x + ', ' + this.y + ')'
}

/**
 * Path
 */

function Path (commands) {
  this.commands = commands
}

Path.prototype.toString = function () {
  return this.commands.join('')
}

Path.M = function (point) {
  this.point = point
}

Path.M.prototype.toString = function () {
  return 'M' + this.point.x + ' ' + this.point.y
}

Path.L = function (to) {
  this.to = to
}

Path.L.prototype.toString = function () {
  return 'L' + this.to.x + ' ' + this.to.y
}

Path.Q = function (control, to) {
  this.control = control
  this.to = to
}

Path.Q.prototype.toString = function () {
  return 'Q' + this.control.x + ' ' + this.control.y +
    ' ' + this.to.x + ' ' + this.to.y
}

Path.Z = function () {}

Path.Z.prototype.toString = function () {
  return 'Z'
}

/**
 * Hex
 */

function Hex (size, orientation, radius) {
  this.orientation = orientation || Hex.POINTY_TOP
  this.radius = Math.round((typeof radius === 'undefined' ? Hex.DEFAULT_RADIUS : radius) / 100 * size)

  const cos30 = Math.cos((30 * Math.PI) / 180)

  if (this.orientation == Hex.FLAT_TOP) {
    this.height = size
    this.width = this.height / cos30
  } else {
    this.width = size
    this.height = this.width / cos30
  }
}

Hex.FLAT_TOP = 'flat'
Hex.POINTY_TOP = 'pointy'
Hex.DEFAULT_RADIUS = 5

Hex.prototype.getPath = function () {
  var height = this.height
  var width = this.width
  var a, b, c, d, e, f

  if (this.orientation === Hex.POINTY_TOP) {
    a = new Vector(width / 2, 0)
    b = new Vector(width, height / 4)
    c = new Vector(width, height * 3 / 4)
    d = new Vector(width / 2, height)
    e = new Vector(0, height * 3 / 4)
    f = new Vector(0, height / 4)
  } else {
    a = new Vector(0, height / 2)
    b = new Vector(width / 4, 0)
    c = new Vector(width * 3 / 4, 0)
    d = new Vector(width, height / 2)
    e = new Vector(width * 3 / 4, height)
    f = new Vector(width / 4, height)
  }

  if (this.radius === 0) {
    return new Path([
      new Path.M(a),
      new Path.L(b),
      new Path.L(c),
      new Path.L(d),
      new Path.L(e),
      new Path.L(f),
      new Path.Z()
    ])
  }

  var right = Vector.fromAngle(b.subtract(a).angle(), this.radius)
  var left = Vector.fromAngle(f.subtract(a).angle(), this.radius)
  var level = this.orientation === Hex.POINTY_TOP ?
  new Vector(0, this.radius) : new Vector(this.radius, 0)

  return new Path([
    new Path.M(a.add(left)),
    new Path.Q(a, a.add(right)),
    new Path.L(b.subtract(right)),
    new Path.Q(b, b.add(level)),
    new Path.L(c.subtract(level)),
    new Path.Q(c, c.add(left)),
    new Path.L(d.subtract(left)),
    new Path.Q(d, d.subtract(right)),
    new Path.L(e.add(right)),
    new Path.Q(e, e.subtract(level)),
    new Path.L(f.add(level)),
    new Path.Q(f, f.subtract(left)),
    new Path.Z()
  ]).toString()
}

Hex.prototype.getColor = function(color, selected) {
  return selected ? Color(color).mix(Color('#333')).hexa() : color
}

Hex.prototype.getStroke = function(selected) {
  return selected ? 'white' : null
}

Hex.prototype.getStrokeWidth = function(selected) {
  return selected ? 2 : 0
}

Hex.prototype.getDashArray = function() {
  return '5,5'
}

Hex.prototype.getViewbox = function () {
  return `0 0 ${this.width} ${this.height}`
}

Hex.prototype.getSvgElement = function (color, selected) {
  let svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svgElement.setAttribute('width', Math.ceil(this.width))
  svgElement.setAttribute('height', Math.ceil(this.height))
  svgElement.setAttribute('viewbox', this.getViewbox())

  let pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  pathElement.setAttribute('fill', this.getColor(color, selected))
  pathElement.setAttribute('stroke', this.getStroke(selected))
  pathElement.setAttribute('stroke-width', this.getStrokeWidth(selected))
  pathElement.setAttribute('stroke-dasharray', this.getDashArray())
  pathElement.setAttribute('d', this.getPath())
  svgElement.appendChild(pathElement)

  return svgElement
}

export default Hex
