<template>

  <Keypress @keypress="onKeyPress"></Keypress>

  <audio v-for="name of audios" :id="`audio-${name}`" :key="name">
    <source :src="getSoundUrl(name, 'ogg')" type="audio/ogg">
    <source :src="getSoundUrl(name, 'mp3')" type="audio/mpeg">
  </audio>

  <div v-if="locked === 0"
       :class="'trashbin ' + (state.trashHilited ? 'hilited' : '')"
       @drop="onDropTrash()"
       @dragover.prevent
       @dragenter.prevent="state.trashHilited = true"
       @dragleave.prevent="state.trashHilited = false" />

  <div class="hexagrid"
       @click="onClickGrid"
       v-click-outside="onClickOutside"
       v-first-touch="onFirstTouch"
       :dragging="!!state.drag"
       :edition="!!state.edit"
       :focused="locked > 0 && hasFocus()"
       :locked="locked"
       :style="divStyle">

    <Hexagon v-for="[,node] of state.model.nodes"
             :key="node.id"
             :nodeId="node.id"
             :orientation="orientation"
             :size="size"
             :border-radius="borderRadius"
             :active="node.active"
             :selected="node.selected"
             :text="node.text"
             :useImage="useImage(node)"
             :info="debug ? `(${node.x}, ${node.y})` : null"
             :color="node.color || '#f5f5f5'"
             :palette="palette.palette"
             :paletteWidth="palette.width"
             :paletteHorizontalSeparator="palette.separator"
             :style="{ top: (padding + top(node)) + 'px', left: (padding + left(node)) + 'px' }"
             :edited="state.edit == node"
             :focused="node.focused"
             :locked="locked > 0"
             :hilited="state.hilite == node"
             :draggable="locked < 2 && node.active && !state.edit"
             :interceptEnter="state.interceptEnter"
             @change="s => { onHexagonChanged(node,s) }"
             @editdone="s => { onHexagonDoneEdit(node) }"
             @click.stop="onClick($event, node)"
             @dragstart="startDrag($event, node)"
             @dragend="state.drag = null"
             @drop="onDrop($event, node)"
             @dragover.prevent
             @dragenter.prevent="state.hilite = node"
             @dragleave.prevent="() => { if (state.hilite == node) state.hilite = null }" />
  </div>
</template>

<script>
  import Keypress from '@/components/Keypress.vue'
  import Hexagon from '@/components/Hexagon.vue'
  import { reactive, computed, watch } from 'vue'
  import Hex from '@/hexagon.js'
  import HexModel from '@/model.js'
  import {} from 'drag-drop-touch'
  import saveAs from 'save-as'

  const MAX_STACK_SIZE = 32

  export default {
    name: 'Hexagrid',
    components: {
      Keypress,
      Hexagon
    },
    props: {
      size: Number,
      angle: {
        type: Number,
        default: 0
      },
      radius: {
        type: Number,
        default: 1
      },
      useGrid: {
        type: Boolean,
        default: false
      },
      gridWidth: {
        type: Number,
        default: 4
      },
      gridHeight: {
        type: Number,
        default: 3
      },
      autoGrow: {
        type: Boolean,
        default: true
      },
      gutter: {
        type: Number,
        default: 3
      },
      debug: {
        type: Boolean,
        default: false
      },
      locked: {
        type: Number,
        default: 0
      },
      sounds: {
        type: Boolean,
        default: true
      },
      legacyPalette: {
        type: Boolean,
        default: false
      },
      customDragImage: {
        type: Boolean,
        default: false
      },
    },
    emits: ['init', 'load', 'change', 'select', 'focus'],
    setup(props, { emit }) {

      // https://fr.wikipedia.org/wiki/Couleur_du_Web
      // https://coolors.co/3a86ff-2f9d50-fb5607-f5006a-8338ec-6d7588-ffb703-e63946-151e3f-3d3d3d
      // shades: we takes for each color the shades 8, 13, 18 (1 being the darkest)

      const colors = []

      colors.push(['imperial red', '#EF8089', '#E42535', '#91121D'])
      colors.push(['orange red', '#FF9670', '#FF4500', '#A32C00'])
      colors.push(['orange', '#FFCD70', '#FFA500', '#A36A00'])
      colors.push(['gold', '#FFEA70', '#FFD700', '#A38B00'])
      colors.push(['custom green', '#91DEA8', '#43C76A', '#267E40'])
      colors.push(['cyan', '#ADFFFF', '#00FFFF', '#00A3A3'])
      colors.push(['magenta', '#FF70FF', '#FF00FF', '#A300A3'])
      colors.push(['custom pink', '#FF70AE', '#FF0A74', '#A30047'])
      colors.push(['custom mauve', '#AE7CF4', '#731EEB', '#460D96'])
      colors.push(['custom blue', '#70A7FF', '#0A68FF', '#003FA3'])
      colors.push(['custom gray blue', '#93A5DC', '#4665C3', '#283D7B'])
      colors.push(['gray', '#B8B8B8', '#808080', '#525252'])

/*
      colors.push(['red', '#FF7070', '#FF0000', '#A30000'])
      colors.push(['custom red', '#F07F89', '#E52434', '#92111C'])
      colors.push(['custom orange', '#FD9E72', '#FB590E', '#A13502'])
      colors.push(['custom yellow-orange', '#FFD770', '#FFBA0A', '#A37500'])
      colors.push(['blue', '#7070FF', '#0000FF', '#0000A3'])
      colors.push(['yellow', '#FFFF70', '#FFFF00', '#A3A300'])
      colors.push(['green (lime)', '#70FF70', '#00FF00', '#00A300'])
      colors.push(['brown', '#E28D8D', '#CD3C3C', '#822121'])
*/

      const palette = computed(() => {

        if (props.legacyPalette) return {
          palette: ['#3A86FF', '#2F9D50', '#FB5607', '#F5006A', '#8338EC', '#6D7588', '#19254D', '#FFB703', '#E63946', 'black', 'teal', 'maroon', 'navy', '#3D3D3D', '#2B6CC4', '#C0448F'],
          width: 4,
          separator: false
        }

        const width = Math.round(colors.length / 2)
        const palette = []

        // 6 first colors with 3 vertical shades for each
        for (let shadeIndex = 3; shadeIndex > 0; shadeIndex--) {
          for (let colorIndex = 0; colorIndex < width; colorIndex++) {
            palette.push(colors[colorIndex][shadeIndex])
          }
        }

        // 6 last colors with 3 vertical shades for each
        for (let shadeIndex = 3; shadeIndex > 0; shadeIndex--) {
          for (let colorIndex = width; colorIndex < colors.length; colorIndex++) {
            palette.push(colors[colorIndex][shadeIndex])
          }
        }

        return {
          palette,
          width,
          separator: true
        }

      })

      const getInitialState = () => {
        // use a function returning a new object each time
        // non-primitive state properties (like undos and redos) are otherwise afterwards affected by the actual state
        return {
          model: new HexModel(props.angle, options.value),
          hilite: null,
          edit: null,
          drag: null,
          trashHilited: false,
          interceptEnter: true,
          lastColor: colors[0][2],
          undos: [],
          redos: [],
          editionCounter: 1
        }
      }

      // computed model options derived from props
      const options = computed(() => {
        return {
          radius: props.locked === 2 ? 0 : props.radius,
          useGrid: props.locked === 2 ? false : props.useGrid,
          gridWidth: props.gridWidth,
          gridHeight: props.gridHeight,
          autoGrow: props.autoGrow,
          debug: false
        }
      })

      // init state
      if (props.debug) console.log(`Creating model with options: ${JSON.stringify(options.value, null, 2)}`)
      const state = reactive(getInitialState())
      emit('init')

      const clear = () => {
        const initialState = getInitialState()
        for (let p in initialState) state[p] = initialState[p]
        changed()
      }

      const center = () => {
        stack()
        state.model.center()
        changed()
      }

      // download json file
      const download = filename => {
        let blob = new Blob([getJSON()], { type: 'application/json;charset=utf-8' })
        saveAs(blob, filename)
      }

      // load JSON string
      const _load = json => {
        try {

          // first clear state
          const initialState = getInitialState()
          for (const p in initialState) state[p] = initialState[p]

          // then override with saved state
          const reviver = (key, value) => { return key == 'model' ? HexModel.restore(value, value.angle, options.value) : value }
          let savedState = JSON.parse(json, reviver)
          for (const p in savedState) state[p] = savedState[p]

        } catch (e) {
          alert(e)
        }
      }

      // load JSON string
      const loadJSON = json => {
        console.log(`Loading JSON document with a size of ${json.length} bytes`)
        _load(json)
        emit('load', null, state.model)
      }

      // load JSON file
      const loadFile = file => {
        const reader = new FileReader()
        reader.onload = () => {
          console.log("Loading JSON file " + file.name)
          _load(reader.result)
          emit('load', file.name, state.model, getJSON())
        }
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.readAsText(file)
      }

      const cos30 = Math.cos((30 * Math.PI) / 180)

      // computed orientation derived from angle
      const orientation = computed(() => {
        return props.angle % 60 == 0 ? Hex.POINTY_TOP : Hex.FLAT_TOP
      })

      // computed borderRadius derived from gutter
      const borderRadius = computed(() => {
        return props.gutter ? 5 : 0
      })

      // computed grid cell size derived from size and gutter
      const s = computed(() => {
        return props.size * (1 + props.gutter / 100)
      })

      // computed grid cell width derived from cell size
      const w = computed(() => {
        return orientation.value == Hex.POINTY_TOP ? s.value / 2 : (s.value / cos30) * 0.75
      })

      // computed grid cell height derived from cell size
      const h = computed(() => {
        return orientation.value == Hex.POINTY_TOP ? (s.value / cos30) * 0.75 : s.value / 2
      })

      // computed padding derived from size
      const padding = computed(() => {
        return props.size / 4
      })

      const left_ = node => {
        return node.x * w.value
      }

      const top_ = node => {
        return node.y * h.value
      }

      // computed viewBox
      const viewBox = computed(() => {
        let viewBox = { left: null, right: null, top: null, bottom: null }

        for (let [, node] of state.model.nodes) {
          viewBox.left = viewBox.left === null ? left_(node) : Math.min(viewBox.left, left_(node))
          viewBox.right = viewBox.right === null ? left_(node) : Math.max(viewBox.right, left_(node))
          viewBox.top = viewBox.top === null ? top_(node) : Math.min(viewBox.top, top_(node))
          viewBox.bottom = viewBox.bottom === null ? top_(node) : Math.max(viewBox.bottom, top_(node))
        }

        viewBox.width = viewBox.right - viewBox.left + s.value / (orientation.value == Hex.POINTY_TOP ? 1 : cos30)
        viewBox.height = viewBox.bottom - viewBox.top + s.value / (orientation.value == Hex.POINTY_TOP ? cos30 : 1)

        delete viewBox.bottom
        delete viewBox.right

        return viewBox
      })

      // div css style computed from size, padding and viewbox
      const divStyle = computed(() => {
        return {
          '--size': props.size + 'px',
          height: 2 * padding.value + viewBox.value.height + 'px',
          width: 2 * padding.value + viewBox.value.width + 'px',
          border: props.debug ? '1px solid red' : '0'
        }
      })

      const top = node => {
        return top_(node) - viewBox.value.top
      }

      const left = node => {
        return left_(node) - viewBox.value.left
      }

      // when changing angle call setAngle to convert if needed
      watch([() => props.angle], () => {
        if (state.model.setAngle(props.angle)) {
          console.log(`Changed angle to ${props.angle}°`)
          // save model if angle changed: not really necessary but will prevent an angle conversion to happen on restore
          // this conversion can cause hexagons to be shifted by one position on the grid
          // indeed, going from angle A to B to C does not give the exact same result as going from A to C directly
          changed()
        }
      })

      // when changing a display option re-create empty nodes
      watch([() => options.value], () => {
        if (props.debug) console.log(`Settings model options: ${JSON.stringify(options.value, null, 2)}`)
        for (let option in options.value) state.model[option] = options.value[option]
        state.model.addEmptyNodes()
      })

      // start drag and remember coordinates of the dragged hexagon
      const startDrag = (event, node) => {

        // inhibit drag on main div (used for multi select)
        event.stopPropagation()

        // sometimes on FF some text is selected inside the hexagon and the text node becomes the event target
        if (!isClass(event.target, 'hexagon', true)) {
          console.log('Ignore drag on invalid target and clear current text selection')
          getSelection().empty()
          event.preventDefault()
          return false
        }

        // if there is a current focus and we didn't drag a focused hexagon, clear focus and do nothing else
        if (hasFocus() && !node.focused) {
          console.log('Ignore drag on unfocused node and clear current focus')
          clearFocus()
          event.preventDefault()
          return false
        }

        // if there is a current selection and we didn't drag a selected hexagon, clear selection and do nothing else
        if (hasSelection() && !node.selected) {
          console.log('Ignore drag on unselected node and clear current selection')
          clearSelection()
          event.preventDefault()
          return false
        }

        state.drag = node
        event.dataTransfer.effectAllowed = 'move'
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.setData('nodeId', node.id)
        playSound('up')

        // override default drag image with SVG element
        // NB1: just cloning the original .hexagon div works but still causes visual issues on Chrome
        //      and these visual issues are the very reason we set up a custom drag image
        //      by creating a new div element from scratch, the visual issues disappear
        // NB2: we could just copy innerHTML of dragged element into drag-div
        //      instead of creating a SVG element from scratch with hex.getSvgElement
        //      it works well but appears to be much slower on FF mobile!
        if (props.customDragImage) {
          // create or re-use drag-div element
          let div = document.getElementById('drag-div')
          if (div) {
            if (props.debug) console.log('Re-using drag-div element')
            while (div.firstChild) div.removeChild(div.lastChild)
          } else {
            console.log('Creating drag-div element')
            div = document.createElement('div')
            div.setAttribute('id', `drag-div`)
            div.classList.add('drag-svg')
            div.classList.add('hexagon')
          }

          // create svg element
          let hex = new Hex(props.size, orientation.value, borderRadius.value)
          let svgElement = hex.getSvgElement(node.color, node.selected)

          // get original text div element in dragged node
          let textElement = null
          for (let i = 0; i < event.target.childNodes.length; i++)
            if (event.target.childNodes[i].className == 'text') {
              textElement = event.target.childNodes[i]
              break
            }

          // create text element
          let text = document.createElement('div')
          text.classList.add('text')
          if (textElement) for (let attr of ['placeholder', 'empty']) text.setAttribute(attr, textElement.getAttribute(attr))
          text.innerText = node.text

          // add svg and text in main div and set --size var
          div.setAttribute('style', `--size: ${props.size}px;`)
          for (let attr of ['orientation']) div.setAttribute(attr, event.target.getAttribute(attr))
          div.appendChild(svgElement)
          div.appendChild(text)

          // we need to add the div to the DOM (https://stackoverflow.com/questions/43790022/html5-draggable-setdragimage-doesnt-work-with-canvas-on-chrome)
          document.getElementById('app').appendChild(div)

          // set drag image centered around pointer
          event.dataTransfer.setDragImage(div, hex.width / 2, hex.height / 2)
        }
      }

      // swap dropped node with originally dragged node
      const onDrop = (event, node) => {
        state.hilite = null
        const nodeId = event.dataTransfer.getData('nodeId')
        playSound('down')

        const metaKey = event.ctrlKey || event.shiftKey || event.metaKey

        stack()

        try {
          if (props.locked === 0 && metaKey) state.model.copy(nodeId, node.x, node.y)
          else state.model.move(nodeId, node.x, node.y)
        } catch (e) {
          alert(e.message)
        }

        changed()
      }

      // remove dropped node
      const onDropTrash = () => {
        state.trashHilited = false
        const nodeId = event.dataTransfer.getData('nodeId')
        playSound('delete')
        stack()
        state.model.remove(nodeId)
        changed()
      }

      const isClass = (node, classname, not_recursive) => {
        if (node && node.className && node.className.match && node.className.match(new RegExp(classname, 'g'))) return true
        if (not_recursive) return false
        return node && node.parentNode ? isClass(node.parentNode, classname) : false
      }

      const onClick = (event, node) => {
        let message = `Clicked on ${node.active ? 'active' : 'empty'} ${state.edit == node ? 'edited ' : ''}hexagon ${node.id}`

        const metaKey = event.ctrlKey || event.shiftKey || event.metaKey

        // ignore clicks on color input
        if (isClass(event.target, 'colorpicker')) {
          if (props.debug) console.log(message + ` : ignoring click on color picker`)
          return false
        }

        // if there is a current focus and we clicked without the alt key, clear focus and do nothing else
        if (!event.altKey && hasFocus()) {
          console.log(message + ` without alt key : clear current focus`)
          clearFocus()
          return false
        }

        // if there is a current selection and we clicked without the metakey, clear selection and do nothing else
        if (!metaKey && hasSelection()) {
          console.log(message + ` without meta key : clear current selection`)
          clearSelection()
          return false
        }

        // in edition mode
        if (state.edit) {
          // quit edition mode if clicked outside edited hexagon, otherwise do nothing
          if (state.edit != node) {
            console.log(message + ` : leaving edit mode`)
            stopEdition()
          } else console.log(message + ` : staying in edit mode`)
        }

        // not in edition mode and clicked node is active
        else if (node.active) {
          if (props.locked < 2 && metaKey) {
            console.log(message + ` with meta key : toggle select hexagon`)
            node.selected = !node.selected

            // emit select event
            emit('select')
          }
          else if (props.locked === 0) {
            console.log(message + ` : going to edit mode`)
            state.edit = node
          }
          else if (event.altKey) {
            console.log(message + ` readonly with alt key: toggle highlight hexagon`)
            node.focused = !node.focused
          }
          else {
            console.log(message + ` readonly: focus on hexagon`)

            // emit focus event
            emit('focus', event.target)
          }
        }

        // not in edition mode and clicked node is empty
        else {
          if (metaKey || event.altKey) {
            console.log(message + ` with meta or alt key : do nothing`)
          }
          else if (props.locked === 0) {
            stack()
            console.log(message + ` : creating new hexagon`)
            state.edit = state.model.add(node.x, node.y, '', state.lastColor)
            changed()
          }
        }
      }

      // stop edition and clear selection when clicking outside edited hexagon
      const onClickGrid = event => {
        if (!isClass(event.target, 'hexagon')) {

          if (state.edit) {
            console.log('Clicked in grid outside of any hexagon: leaving edit mode')
            stopEdition()
          }

          if (props.locked > 0 && !event.altKey && hasFocus()) {
            console.log(`Clicked in grid outside of any hexagon without alt key: clear current focus`)
            clearFocus()
          }

          const metaKey = event.ctrlKey || event.shiftKey || event.metaKey
          if (!metaKey && hasSelection()) {
            console.log(`Clicked in grid outside of any hexagon without meta key: clear current selection`)
            clearSelection()
          }
        }
      }

      // stop edition and clear selection when clicking outside grid
      const onClickOutside = () => {
        if (state.edit) {
          console.log('Clicked outside of grid: leaving edit mode')
          stopEdition()
        }

        if (props.locked > 0 && !event.altKey && hasFocus()) {
          console.log(`Clicked outside of grid without alt key: clear current focus`)
          clearFocus()
        }

        const metaKey = event.ctrlKey || event.shiftKey || event.metaKey
        if (!metaKey && hasSelection()) {
          console.log(`Clicked outside of grid without meta key: clear current selection`)
          clearSelection()
        }
      }

      const onHexagonChanged = (node, s) => {
        if (props.locked === 0 && node.active) {
          if (props.debug) console.log(`Content changed for ${node.id} : ${s.color} ${s.text}`)
          const colorChanged = node.color != s.color

          // use same stack id while editing the text, so that we keep only the state before the first change
          stack(false, colorChanged ? null : node.id + ':text_edit:' + state.editionCounter)

          node.text = s.text
          state.model.setColor(node.id, s.color)
          state.lastColor = s.color

          if (colorChanged) {

            // leave edit mode when selecting a color since the content editable has anyway lost its focus
            if (state.edit == node) {
              console.log(`Changed color for edited hexagon ${node.id} : leaving edit mode`)
              stopEdition()
            }

            // save if color changed, for text changes save will be done in stopEdition
            else changed()
          }
        }
      }

      const onHexagonDoneEdit = node => {
        if (props.debug) console.log(`Edition done for ${node.id}`)
        stopEdition()
      }

      const stopEdition = () => {
        state.editionCounter++
        state.edit = null
        changed()
      }

      const onFirstTouch = () => {
        console.log('First touch on grid: disabling interceptEnter')
        state.interceptEnter = false
      }

      const getJSON = () => {
        return JSON.stringify({
          model: state.model,
          lastColor: state.lastColor
        })
      }

      const changed = () => {

        // emit change event
        emit('change', state.model, getJSON())
      }

      const playSound = name => {
        if (props.sounds) {
          const audio = document.getElementById('audio-' + name)
          audio.play()
        }
      }

      const getSoundUrl = (name, format) => {
        const sounds = require.context('../assets/')
        return sounds(`./${name}.${format}`)
      }

      const useImage = node => {
        if (!node || !node.active || !node.text) return ''
        if (node.text.match(/Dessine[\s-]+moi\s+un\s+mouton/i)) return "sheep"
        if (node.text.match(/OCTOpUSS/)) return "octopuss"
        return ''
      }

      const hasFocus = () => {
        return state.model.hasFocus()
      }

      const clearFocus = () => {
        state.model.clearFocus()
      }

      const hasSelection = () => {
        return state.model.hasSelection()
      }

      const clearSelection = () => {
        state.model.clearSelection()

        // emit select event
        emit('select')
      }

      const setSelection = (nodeIds) => {
        state.model.setSelection(nodeIds)

        // emit select event
        emit('select')
      }

      const appendSelection = (nodeIds) => {
        state.model.appendSelection(nodeIds)

        // emit select event
        emit('select')
      }

      const removeSelected = () => {

        if (props.locked > 0) {
          alert("Deleting hexagons is allowed in edition mode only.")
          return false
        }

        const nbSelected = state.model.nbSelected()
        if (nbSelected > 0) {
          const message = 'Are you sure you want to delete the ' + (nbSelected === 1 ? "selected hexagon" : `${nbSelected} selected hexagons`) + ' ?'
          if (confirm(message)) {
            stack()
            playSound('delete')
            state.model.removeSelected()
            changed()
          }
        }
      }

      const stack = (redo, source) => {

        // when adding to the Undo stack
        // if a source is specified and the last change was already from this source, don't add a new state
        if (!redo && source && state.undos.length) {
          const last = state.undos[state.undos.length - 1]
          if (last && last.source === source) {
            if (props.debug) console.log(`Not adding to Undo stack for source ${source}`)
            return false
          }
        }

        // store state in undo or redo stack
        const stack = redo ? state.redos : state.undos
        stack.push({ source, json: getJSON() })
        if (stack.length > MAX_STACK_SIZE) stack.shift()
        if (props.debug) console.log(`Size of Undo / Redo stack is now ${state.undos.length} / ${state.redos.length}`)
      }

      const _apply = (json) => {
        try {
          const reviver = (key, value) => { return key == 'model' ? HexModel.restore(value, value.angle, options.value) : value }
          let savedState = JSON.parse(json, reviver)
          for (let p in savedState) state[p] = savedState[p]
          changed()
        } catch (e) {
          alert(e)
        }
      }

      const hasUndo = () => {
        return state.undos.length > 0
      }

      const hasRedo = () => {
        return state.redos.length > 0
      }

      const undo = () => {
        const last = state.undos.pop()
        if (last) {
          if (props.debug) console.log('Applying last model from Undo stack')
          // playSound('undo')
          stack(true)
          _apply(last.json)
        }
        else {
          if (props.debug) console.log('Undo stack empty')
          playSound('noaction')
        }
      }

      const redo = () => {
        const last = state.redos.pop()
        if (last) {
          if (props.debug) console.log('Applying last model from Redo stack')
          // playSound('redo')
          stack()
          _apply(last.json)
        }
        else {
          if (props.debug) console.log('Redo stack empty')
          playSound('noaction')
        }
      }

      const onKeyPress = (event, type) => {

        if (type !== 'up' || state.edit) return false

        const key = event.key;
        const keyCode = event.keyCode || event.charCode;
        const Ctrl = event.ctrlKey || event.metaKey
        const Shift = event.shiftKey

        if (key === "Control" || key === "Shift") return true

        if (props.debug) console.log(`Pressed ${type} ${Ctrl ? 'Ctrl-' : ''}${Shift ? 'Shift-' : ''}${key} ${keyCode}`)

        if (state.edit) {
          if (props.debug) console.log('Ìgnore keypress in edition mode')
          return true
        }

        // delete or backspace
        if (keyCode === 8 || keyCode === 46) removeSelected()

        // Shift-Ctrl-Z
        else if (Shift && Ctrl && keyCode === 90) redo()

        // Ctrl-Z
        else if (Ctrl && keyCode === 90) undo()
      }

      const audios = ['up', 'down', 'delete', 'noaction', 'undo', 'redo']

      return {
        viewBox,
        divStyle,
        top,
        left,
        orientation,
        borderRadius,
        padding,
        startDrag,
        onDrop,
        onDropTrash,
        onClick,
        onClickGrid,
        onClickOutside,
        onHexagonChanged,
        onHexagonDoneEdit,
        onFirstTouch,
        state,
        clear,
        center,
        download,
        getJSON,
        loadJSON,
        loadFile,
        palette,
        audios,
        getSoundUrl,
        useImage,
        onKeyPress,
        clearFocus,
        hasFocus,
        hasSelection,
        clearSelection,
        setSelection,
        appendSelection,
        removeSelected,
        hasUndo,
        hasRedo,
        undo,
        redo
      }
    }
  }
</script>

<style lang="scss">
  @import '@/styles/Hexagrid';
</style>
